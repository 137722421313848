.container {
    padding: 0.5px;
}
.container::before{
    content: '';
    height: 100%;
    width: 4px;
    background-color: rgb(194 65 12);
    position: absolute;
    left: -1%;
    top: 0%;
    border-radius: 5px;
}

.animateTexts2 {
    animation-delay: 5s;
}

@keyframes animateText {
    from {
        transform: translate(100%,0);
    }
    to {
        transform: translate(-100%,0);
    }
}

.sliding-text {
    animation: slide-text infinite 10s linear;
  }
  
  @keyframes slide-text {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }